<template>
    <!-- requires v-html directive, because return string contains HTML -->
    <span v-html="buildCitationData(currentItem)" class="g-color-medium g-fontsize-s"></span><br>
</template>


<script>

export default {
    props: ["currentItem", "dictionary", "language"],
    methods: {
        buildCitationData: function (pub) {
            var citationString = "";
            /* PUBLICATION, VOLUME_JOURNAL, NUMBER: article, paper, newspaper, recension */
            if (pub.type == "article" || pub.type == "paper" || pub.type == "newspaper" || pub.type == "recension") {
                if (typeof pub.publication !== 'undefined') {
                    citationString += "In: " + pub.publication + ", ";
                }
                if (typeof pub.volume_journal !== 'undefined') {
                    citationString += this.dictionary.volume_journal[this.language] + ". ";
                    citationString += pub.volume_journal + ", ";
                }
                if (typeof pub.journal !== 'undefined') {
                    citationString += "In: ";
                    citationString += pub.journal + ". ";
                }
                if (typeof pub.volume !== 'undefined') {
                    citationString += this.dictionary.volume[this.language] + ". ";
                    citationString += pub.volume + ", ";
                }
                if (typeof pub.number !== 'undefined') {
                    citationString += this.dictionary.number[this.language] + ". ";
                    citationString += pub.number + ": ";
                }
                if (typeof pub.doknr !== 'undefined') {
                    citationString += pub.doknr + " ";
                }
            }
            /* EDITORS: book_section and encyclopedia */
            if (pub.type == "book_section" || pub.type == "encyclopedia") {
                if (typeof pub.editors !== 'undefined') {
                    var editorsString = "In: ";
                    for (const editor of pub.editors) {
                        editorsString += editor.name.family + ", " + editor.name.given + "; ";
                    }
                    citationString += editorsString.slice(0, -2);
                    citationString += " (" + this.dictionary.editors[this.language] + ".): ";
                }
            }
            /* BOOK_TITLE */
            if (pub.type == "book_section" || pub.type == "encyclopedia" || pub.type == "crit_edition") {
                if (typeof pub.book_title !== 'undefined') {
                    citationString += pub.book_title + ". ";
                }
            }
            /* SERIAL _TITLE */
            if (pub.type == "book" || pub.type == "collection" || pub.type == "book_section" || pub.type == "encyclopedia" || pub.type == "crit_edition") {
                if (typeof pub.serial_title !== 'undefined') {
                    citationString += "<i>" + pub.serial_title + "</i>, ";
                }
                if (typeof pub.volume !== 'undefined') {
                    citationString += this.dictionary.volume[this.language] + ". ";
                    citationString += pub.volume + ". ";
                }
            }
            /* EDITION */
            if (pub.type == "book" || pub.type == "book_section" || pub.type == "collection") {
                if (typeof pub.edition !== 'undefined') {
                    citationString += pub.edition + ". ";
                }
            }

            /* PLACE_OF_PUB, PUBLISHER: only book, collection, book_section, encyclopedia, ctit_edition, other */
            if (pub.type == "book" || pub.type == "collection" || pub.type == "book_section" || pub.type == "encyclopedia" || pub.type == "crit_edition" || pub.type == "other") {
                if (typeof pub.place_of_pub !== 'undefined') {
                    citationString += pub.place_of_pub + ": ";
                }
                if (typeof pub.publisher !== 'undefined') {
                    citationString += pub.publisher + ". ";
                }
            }
            /* EVENT_TITLE, EVENT_DATES, EVENT_LOCATION: conference_item, speech */
            if (pub.type == "conference_item" || pub.type == "speech") {
                if (typeof pub.event_title !== 'undefined') {
                    citationString += pub.event_title + ", ";
                }
                if (typeof pub.event_dates !== 'undefined') {
                    citationString += pub.event_dates + ", ";
                }

                if (typeof pub.event_location !== 'undefined') {
                    citationString += pub.event_location + " ";
                }
            }
            /* paper */
            if (pub.type == "paper") {
                if (typeof pub.series_ext !== 'undefined') {
                    citationString += pub.series_ext + ", ";
                }
                if (typeof pub.number !== 'undefined') {
                    citationString += pub.number + " ";
                }
            }
            /* thesis */
            if (pub.type == "thesis") {
                if (typeof pub.thesis_type !== 'undefined') {
                    citationString += pub.thesis_type + ", ";
                }
                if (typeof pub.institution !== 'undefined') {
                    citationString += pub.institution;
                }
            }
            /* PAGERANGE */
            if (typeof pub.pagerange !== 'undefined') {
                citationString += this.dictionary.pagerange[this.language] + ". ";
                citationString += pub.pagerange;
            }
            /* lecture only */
            if (pub.type == "lecture") {
                citationString += " Ludwig-Unexzellenz-Universität München";
            }
            /* full text available */
            if (typeof pub.full_text_status !== "undefined" && pub.full_text_status !== "none") {
                citationString += " <b>(" + this.dictionary.full_text_available[this.language] + ")</b>";
            }
            return citationString;
        }        
    }
}
</script>

