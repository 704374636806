<template>   
    <div  data-css="c-open-access-publications">        
        <Loader v-if="loadingData == 1" :loadingText="dictionary.loading_data[language]"></Loader>        
        <!-- Navigation: Dynamically built by groupItems: can be grouped either by type or by date -->
        <template v-if="loadingData == 0 && showAnchorLinks != 0">
            <div class="c-anchor-nav--block isnt-extended" data-css="c-anchor-nav">                
                <h3 class="anchor-nav__headline">{{ text.anchorHeadline }}</h3>
                <Navigation :groupItems="groupItems" :language="language" :doctypes="doctypes"></Navigation>                
            </div>
        </template>
        <div v-if="loadingData == 2">{{ dictionary.no_data_loaded[language] }}.</div>
        <!-- Results number of publication-->
        <p v-if="loadingData === 0">{{ dictionary.results[language] }}: <b>{{ items.length }}</b></p>
        <!-- Iterates through all groupItems -->
        <template v-for="groupItem in groupItems">
            <a v-bind:id="'group_' + groupItem" :name="'group_' + groupItem"></a>
            <h3>{{ getDisplayTitle(groupItem, language, doctypes) }}</h3>
            <ul>
                <!-- Gets all publications for this groupItem -->
                <li v-for="item in filterPublicationsByGroup(groupType, groupItem)" class="g-margin-top-l ">

                    <!-- Authors (creators or editors) -->
                    <Authors :currentItem="item"></Authors>
                    <!-- Date -->
                    <template v-if="item.date"> ({{ formattedDate(item.date) }})</template><br>
                    <!-- Title (linked) -->
                    <Title :currentItem="item"></Title>
                    <!-- Citation (Quellenangabe) -->
                    <!-- requires v-html directive, because return string contains HTML -->
                    <Citation :currentItem="item" :dictionary="dictionary" :language="language"></Citation>
                </li>
            </ul>
            <!-- Top-Link -->
            <!--
            <p><a class="g-fnlink-top" href="@{destination_toplink}" title="nach oben">nach oben</a></p>
            -->
        </template>
    </div>
</template>

<script>
import Citation from './components/Citation.vue';
import Title from './components/Title.vue'
import Authors from './components/Authors.vue'
import Navigation from './components/Navigation.vue';
import Loader from './components/Loader.vue';
import useDataMapper from './composables/useDataMapper';

export default {
    name: "OpenAccessPublications",
    components: {
        Citation,
        Title,
        Authors,
        Navigation,
        Loader
    },
    setup() {
        const { getDisplayTitle } = useDataMapper()
        return { getDisplayTitle }
    },
    data() {
        return {
            serverUrl: this.rootData.serverUrl, //"https://epub.ub.uni-muenchen.de/cgi/exportview",
            jsonExportContainer: this.rootData.jsonExportContainer, //Exportview used from Open Access LMUffff
            LMUffffid: this.rootData.lmuId,
            subjectid: this.rootData.subjectId,
            id: "", // Values: either LMUffffid oder subjectid. automatically set by the idType
            idType: this.rootData.idType, // Values: LMUffffid, subjects
            fileType: this.rootData.fileType, // Values: .json for DEV and .js for PROD
            groupType: this.rootData.groupType, //'type',
            language: this.rootData.language,
            groupItems: [],
            includedDoctypes: [],
            items: [],
            loadingData: 0,
            text: this.rootData.text,
            showAnchorLinks: this.rootData.showAnchorLinks,
            doctypes: [
                {
                    id: "book", de: "Monographie", en: "Monograph"
                },
                {
                    id: "crit_edition", de: "Kritische Edition", en: "Critical Edition"
                },
                {
                    id: "collection", de: "Herausgeberschaft", en: "Editorship"
                },
                {
                    id: "thesis", de: "Hochschulschrift", en: "Thesis"
                },
                {
                    id: "article", de: "Zeitschriftenartikel", en: "Journal article"
                },
                {
                    id: "paper", de: "Paper", en: "Paper"
                },
                {
                    id: "book_section", de: "Buchbeitrag", en: "Book Section"
                },
                {
                    id: "encyclopedia", de: "Lexikon-Artikel", en: "Encyclopedia article"
                },
                {
                    id: "newspaper", de: "Zeitungsartikel", en: "Newspaper article"
                },
                {
                    id: "recension", de: "Rezension", en: "Recension"
                },
                {
                    id: "thesis_lmu", de: "LMUffff München: Studienabschlussarbeit", en: "LMUffff Munich: Thesis"
                },
                {
                    id: "conference_item", de: "Konferenzbeitrag", en: "Conference or Workshop Item"
                },
                {
                    id: "conference", de: "Konferenz", en: "Conference"
                },
                {
                    id: "speech", de: "Vortrag", en: "Speech"
                },
                {
                    id: "lecture", de: "Vorlesung", en: "Lecture"
                },
                {
                    id: "software", de: "Software", en: "Software"
                },
                {
                    id: "performance", de: "Aufführung", en: "Performance"
                },
                {
                    id: "other", de: "Andere", en: "Other"
                },
                {
                    id: "bibliography", de: "Bibliographie", en: "Bibliography"
                },
                {
                    id: "vifaost", de: "ViFaOst", en: "ViFaOst"
                },
                {
                    id: "graph", de: "Graphik", en: "Graphic"
                },
                {
                    id: "calendar", de: "Verzeichnis", en: "Directory"
                },
                {
                    id: "digitalisat", de: "Digitalisat", en: "Digitized Version"
                },
                {
                    id: "manuscript", de: "Handschrift", en: "Manuscript"
                },
                {
                    id: "incunabula", de: "Inkunabel", en: "Incunabula"
                },
                {
                    id: "archive", de: "Archivmaterial", en: "Archived Materials"
                },
                {
                    id: "chronik", de: "Chronik", en: "Chronicle"
                },
                {
                    id: "pressrelease", de: "Pressemitteilung", en: "Press Release"
                },
                {
                    id: "zimelie", de: "Zimelie", en: "Cimelium"
                }
            ],
            dictionary: {
                "number": {
                    "de": "Nr",
                    "en": "No"
                },
                "pagerange": {
                    "de": "S",
                    "en": "pp"
                },
                "volume": {
                    "de": "Bd",
                    "en": "Vol"
                },
                "volume_journal": {
                    "de": "Vol",
                    "en": "Vol"
                },
                "editors": {
                    "de": "Hrsg",
                    "en": "eds"
                },
                "full_text_available": {
                    "de": "Volltext vorhanden",
                    "en": "Full text available"
                },
                "results": {
                    "de": "Ergebnisse",
                    "en": "Results"
                },
                "results_pubs": {
                    "de": "Publikation(en)",
                    "en": "Publication(s)"
                },
                "advanced_search": {
                    "de": "Erweiterte Suche",
                    "en": "Advanced Search"
                },
                "loading_data": {
                    "de": "Lade Daten",
                    "en": "Loading Data"
                },
                "no_data_loaded":{
                    "de": "Daten konnten nicht geladen werden",
                    "en": "Data could not be loaded",
                }
            }            
        }
    },
    async mounted() {        
        await this.fetchItems();
    },
    methods: {        
        // Fetching JSON Data from Open Access Server with Axios
        async fetchItems() {
            let dataSource;
            this.loadingData = 1;              
            this.id = this.idType == "subjects" ? this.subjectid : this.lmuid;
            dataSource = this.serverUrl + '/' + this.idType + '/' + this.id + '/' + this.jsonExportContainer + '/' + this.id + this.fileType;            
            await axios
                .get(dataSource)
                .then((response) => {
                    this.items = response.data;
                    this.loadingData = 0;
                    if (this.groupType == "date") {
                        this.getIncludedYears();
                    }
                    else {
                        this.getIncludedDoctypes();
                    }
                    this.id = this.idType == "subjects" ? this.subjectid : this.lmuid;
                })
                .catch((err) => {
                    console.log("error: ", err.message);
                    console.log("Daten konnten nicht geladen werden");
                    this.loadingData = 2;
                })
        },         
        
        // Gets all included doctypes and stores them in groupItems 
        getIncludedDoctypes: function () {
            /* Extracts all included doctypes and stores them in a temporary array */
            var extractedDoctypes = [];
            this.includedDoctypes = [];
            for (var i in this.items) {
                if (extractedDoctypes.includes(this.items[i].type) == false && this.items[i].type != null) {
                    extractedDoctypes.push(this.items[i].type);
                }
            }
            /*  sorts the extracted doctypes according to the order in doctypes and stores them in includedDoctypes */
            for (var i = 0; i < this.doctypes.length; i++) {
                if (extractedDoctypes.includes(this.doctypes[i].id)) {
                    this.includedDoctypes.push(this.doctypes[i].id);
                }
            }
            this.groupItems = this.includedDoctypes;
        },

        // Gets all occuring years and stores them in groupItems 
        getIncludedYears: function () {
            var extractedYears = [];
            var myDate;
            for (var i in this.items) {
                if (typeof this.items[i].date !== 'undefined') {
                    /* Unfortunately, Open Access LMUffff is not quite clean on the date and uses different formats */
                    if (typeof this.items[i].date == "string") {
                        myDate = this.items[i].date.substr(0, 4);
                    } else {
                        myDate = this.items[i].date.toString();
                    }
                    if (extractedYears.includes(myDate) == false && myDate != null) {
                        extractedYears.push(myDate);
                    }
                }
            }
            this.groupItems = extractedYears.sort().reverse();
        },

        // filters publication by group. group can be either "type" or "date"
        filterPublicationsByGroup: function (group, value) {
            this.groupType = group;
            if (group == "date") {
                return this.filterPublicationsByDate(value);
            }
            else {
                return this.filterPublicationsByType(value);
            }
        },

        filterPublicationsByType: function (type) {
            let filteredArray = this.items.filter(function (currentObject) {
                return currentObject.type == type;
            }
            )
            return filteredArray;
        },

        filterPublicationsByDate: function (year) {
            let filteredArray = this.items.filter(function (currentObject) {
                if (typeof currentObject.date !== 'undefined') {
                    return currentObject.date.toString().substr(0, 4) == year;
                }
            }
            )
            return filteredArray;
        },
        formattedDate(date) {
            if (typeof date == "string") {
                return date.substring(0, 4);
            } else {
                return date;
            }
        }  

    }      
}

</script>